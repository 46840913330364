<template>
  <div class="main">

    <div class="user_info">
      <el-image class="logo-img" :src="require('@/assets/zhishan_logo.svg')" @click="satis"/>
      <div class="user_detail">
        <span style="font-size: 15px;margin-right: 10px">{{ this.curUser ? this.curUser.realName : '' }}</span>
        <el-avatar :src="this.curUser?this.curUser.avatar:''" style="margin-right: 10px"
                   class="user_photo" @click.native="logout"></el-avatar>
      </div>

    </div>

    <div style="margin: 0px;padding-left: 20px">
      <hr style="width: 100%; margin: 0px" color="#dddddd" size="0.5"/><!--修改的样式-->
    </div>

    <div class="order_filter">
      <div>
        <el-select style="margin-right: 10px;" v-model="pager.organ" slot="prepend" placeholder="请选择认证机构"
                   size="mini"
                   clearable @clear="clearOrgan" @change="changeOrgan">
          <el-option v-for="(typeItem, index) in organList" :label="typeItem.name" :value="typeItem.name"></el-option>
        </el-select>

        <el-select style="margin-right: 10px;" v-model="pager.project" slot="prepend" placeholder="请选择认证项目"
                   size="mini"
                   clearable @clear="clearProject" @change="changeProject">
          <el-option v-for="(typeItem, index) in projectList" :label="typeItem.name" :value="typeItem.name"></el-option>
        </el-select>

        <el-select style="margin-right: 10px;" v-model="pager.trainTeacher" slot="prepend" placeholder="请选择培训老师"
                   size="mini"
                   clearable @clear="clearTeacher" @change="changeTeacher">
          <el-option v-for="(typeItem, index) in teacherList" :label="typeItem['realName']"
                     :value="typeItem['realName']"></el-option>
        </el-select>

        <el-input style="width: 230px" placeholder="请输入客户名称" v-model="pager.customer"
                  class="input-with-select" size="mini" @keyup.enter.native="customerEnter">
        </el-input>

        <el-date-picker
            v-model="pager.orderDate"
            type="date"
            value-format="yyyy-MM-dd"
            size="mini"
            style="margin-left: 10px"
            placeholder="接单日期"
        />

        <el-date-picker
            v-model="pager.trainDate"
            type="date"
            value-format="yyyy-MM-dd"
            size="mini"
            style="margin-left: 10px"
            placeholder="培训日期"
        />
        <el-button type="primary" size="mini" style="margin-left: 20px" @click="filterList">搜索</el-button>
        <el-button type="info" size="mini" @click="resetFilter">重置</el-button>
      </div>

      <el-badge :value="1" class="item" type="primary" style="margin-right: 20px" v-if="false">
        <el-button size="small" style="font-weight: bold">消息提醒</el-button>
      </el-badge>

    </div>

    <div class="order_filter1" v-if="showVerify">

      <el-select style="margin-right: 10px" v-model="pager.isVerified" slot="prepend" placeholder="请选择是否报销核验"
                 size="mini"
                 clearable @clear="clearVerification" @change="changeVerification">
        <el-option v-for="(typeItem, index) in verificationList" :label="typeItem.name"
                   :value="typeItem.value"></el-option>
      </el-select>

      <el-date-picker
          v-model="pager.verificationDateList"
          type="daterange"
          range-separator="至"
          size="mini"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
      />


    </div>

    <div class="order_stauts_filter">
      <el-checkbox label="全部" :indeterminate="statusIndeterminate" v-model="checkAllStatus"
                   @change="handleCheckAllStatusChange"></el-checkbox>
      <el-checkbox-group v-model="checkedStatus" class="order_status" @change="handleCheckedStatusChange">
        <el-checkbox v-for="status in statusList" :label="status" :key="status">{{ status }}</el-checkbox>
      </el-checkbox-group>

    </div>

    <div class="order_stauts_filter" v-show="false">
      <el-checkbox label="全部" v-model="checkAllTime"
                   @change="handleCheckAllTimeChange"></el-checkbox>
      <el-checkbox-group v-model="checkedTime" class="order_status" @change="handleCheckedTimeChange">
        <el-checkbox v-for="time in timeList" :label="time" :key="time">{{ time + '月' }}</el-checkbox>
      </el-checkbox-group>

    </div>

    <el-row style="margin-top: 10px;margin-left: 20px">
      <!--      <el-button type="primary" size="mini" @click="filterList">搜索</el-button>-->
      <!--      <el-button type="info" size="mini" @click="resetFilter">重置</el-button>-->
      <el-button type="primary" size="mini" @click="newOrder">接单</el-button>
      <el-button type="info" size="mini" @click="exportOrder">导出</el-button>
      <el-button type="danger" size="mini" @click="delOrder" v-if="showDel">删除</el-button>
      <!--      <el-button type="success" size="mini" @click="sortOrder">按培训时间培训</el-button>-->
      <!--      <el-button type="success" size="mini" @click="sortOrder">按审核时间培训</el-button>-->
    </el-row>

    <div style="margin-left: 10px;">
      <el-table
          :row-style="{height: '35px'}"
          :header-cell-style="{'text-align':'left', color: '#666', 'font-weight': 'bold'}"
          :cell-style="{padding: '0px', 'text-align':'left'}"
          ref="multipleTable"
          :data="orderListData"
          tooltip-effect="dark"
          @cell-dblclick="orderClick"
          style="width: 100%;font-size: 12px;"
          @selection-change="handleOrderSelectionChange">
        <el-table-column
            align="center"
            type="selection"
            width="29">
        </el-table-column>
        <el-table-column
            align="center"
            label="订单号"
            width="100">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.newOrder.id
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="客户名称"
            width="200">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.customer == null ? '' : scope.row.customer.company
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="认证机构"
            width="100">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.newOrder.organ
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="咨询项目"
            width="100">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.newOrder.project
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="审核老师"
            width="150">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.schedule ? scope.row.schedule.auditTeachers : ''
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="审核时间"
            width="200">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.schedule ? scope.row.schedule.auditDate : ''
              }}</span>
          </template>

        </el-table-column>
        <el-table-column
            align="center"
            label="培训老师"
            width="150">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.schedule ? scope.row.schedule.trainTeachers : ''
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="培训时间"
            width="150">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.schedule ? scope.row.schedule.trainDate : ''
              }}</span>
          </template>

        </el-table-column>

        <el-table-column
            align="center"
            label="费用明细"
            width="450">
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                '认证费:'
              }}
                {{ scope.row.newOrder.certificationFee ? scope.row.newOrder.certificationFee : '无' }}  {{ '、咨询费:' }}  {{
                scope.row.newOrder.consultingFee ? scope.row.newOrder.consultingFee : '无'
              }}
                {{
                '、年审认证费:'
              }}  {{
                scope.row.newOrder.nextCertificationFee ? scope.row.newOrder.nextCertificationFee : '无'
              }}  {{
                '、年审咨询费:'
              }}  {{ scope.row.newOrder.nextConsultingFee ? scope.row.newOrder.nextConsultingFee : '无' }}
              </span>
          </template>

        </el-table-column>
        <el-table-column
            align="center"
            prop="name"
            width="120"
            label="咨询费付款时间"
        >
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.audit ? scope.row.audit.conPayDate : ''
              }}</span>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            prop="name"
            width="120"
            label="认证费付款时间"
        >
          <template v-slot="scope">
       <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
           scope.row.audit ? scope.row.audit.cerPayDate : ''
         }}</span>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            prop="name"
            width="100"
            label="出证时间"
        >
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.license ? scope.row.license.licenseDate : ''
              }}</span>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            prop="name"
            width="100"
            label="初审咨询费"
        >
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.newOrder.certificationFee
              }}</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="name"
            width="100"
            label="初审认证费"
        >
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.newOrder.consultingFee
              }}</span>
          </template>
        </el-table-column>


        <el-table-column
            align="center"
            prop="name"
            width="300"
            label="客户信息"
        >
          <template v-slot="scope">
            <span :class="scope.row.newOrder.isAllPayed == '1' ? 'blue': 'black'">{{
                scope.row.customer ? scope.row.customer.other : ''
              }}</span>
          </template>
        </el-table-column>
      </el-table>

    </div>

    <div class="pagination">
      <el-pagination
          @size-change="handleOrderSizeChange"
          @current-change="handleCurrentOrderChange"
          @prev-click="handlePreOrder"
          @next-click="handleNextOrder"
          :page-size="20"
          layout="total, sizes, prev, pager, next, jumper"
          :total="orderTotalCount">
      </el-pagination>
    </div>

    <!--    <div class="copy_right" style="margin-bottom: 25px">-->
    <!--      <div style="font-size: 14px;font-weight: normal;color: black">-->
    <!--        Copyright © 2023&nbsp;&nbsp;&nbsp;苏州颜回软件科技有限公司&nbsp;版权所有&nbsp;&nbsp;&nbsp;-->
    <!--        <a data-v-202b8482="" href="http://beian.miit.gov.cn/" target="_blank"  style="color: black;text-decoration: none;font-size: 14px">苏ICP备2023024657号-1</a>-->
    <!--      </div>-->
    <!--    </div>-->

    <el-drawer
        title="接单"
        :visible.sync="newOrderDrawer"
        direction="rtl"
        size="80%"
        :wrapperClosable="false"
        :close-on-press-escape="false"
    >
      <el-form ref="orderForm" class="orderForm" label-position="left">
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="单据类型" label-width="105px">
              <el-select v-model="orderForm.type" placeholder="请选择" size="mini" style="width: 230px">
                <el-option v-for="(item) in oderTypeList" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="认证项目" label-width="105px">
              <el-select v-model="orderForm.project" placeholder="请选择" size="mini" style="width: 230px">
                <el-option v-for="(typeItem, index) in projectList" :label="typeItem.name"
                           :value="typeItem.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="认证机构" label-width="105px">
              <el-select v-model="orderForm.organ" placeholder="请选择" size="mini" style="width: 230px">
                <el-option v-for="(typeItem, index) in organList" :label="typeItem.name"
                           :value="typeItem.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="出证时间" label-width="105px">
              <!--              <el-select v-model="orderForm.certificationTime" placeholder="请选择" size="mini" style="width: 230px">-->
              <!--                <el-option v-for="(item) in certificationTimeList" :label="item"-->
              <!--                           :value="item"></el-option>-->
              <!--              </el-select>-->
              <el-input v-model="orderForm.certificationTime" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="认证费" label-width="105px">
              <el-input v-model="orderForm.certificationFee" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="咨询费" label-width="105px">
              <el-input v-model="orderForm.consultingFee" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年审认证费" label-width="105px">
              <el-input v-model="orderForm.nextCertificationFee" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年审咨询费" label-width="105px">
              <el-input v-model="orderForm.nextConsultingFee" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="红包" label-width="105px">
              <el-input v-model="orderForm.redEnvelope" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员费" label-width="105px">
              <el-input v-model="orderForm.membershipFee" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="授权费" label-width="105px">
              <el-input v-model="orderForm.licensingFee" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其它费用" label-width="105px">
              <el-input style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="协议价" label-width="105px">
              <el-input v-model="orderForm.agreementPrice" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开票公司" label-width="105px">
              <el-input v-model="orderForm.invoiceCompany" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="返款金额" label-width="105px">
              <el-input v-model="orderForm.returnMoney" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="返款日期" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    v-model="orderForm.returnDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="返款日期"
                />
              </div>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="差旅报销" label-width="105px">
              <el-radio v-model="orderForm.reimbursementType" label="0">全包</el-radio>
              <el-radio v-model="orderForm.reimbursementType" label="1">客户承担</el-radio>
              <el-radio v-model="orderForm.reimbursementType" label="2">审核由机构承担、辅导由客户承担</el-radio>
              <el-radio v-model="orderForm.reimbursementType" label="3">其它</el-radio>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="" label-width="105px">
              <el-input v-model="orderForm.reimbursementRemark" style="width: 230px" size="mini"
                        :disabled="orderForm.reimbursementType!=3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <!--          <el-col :span="6">-->
          <!--            <el-form-item label="客户姓名" label-width="105px">-->
          <!--              <el-input v-model="customerForm.name" style="width: 230px" size="mini"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->

          <!--          <el-col :span="6">-->
          <!--            <el-form-item label="联系方式" label-width="105px">-->
          <!--              <el-input v-model="customerForm.phone" style="width: 230px" size="mini"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->

          <!--          <el-col :span="6">-->
          <!--            <el-form-item label="邮箱" label-width="105px">-->
          <!--              <el-input v-model="customerForm.mail" style="width: 230px" size="mini"></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->

          <el-col :span="6">
            <el-form-item label="公司名称" label-width="105px">
              <el-input v-model="customerForm.company" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <!--        <el-row style="width: 100%">-->
        <!--          <el-col :span="12">-->
        <!--            <el-form-item label="公司地址" label-width="105px">-->
        <!--              <el-input v-model="customerForm.address" style="width: 520px" size="mini"></el-input>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->

        <!--          <el-col :span="12">-->
        <!--            <el-form-item label="其它信息" label-width="105px">-->
        <!--              <el-input v-model="customerForm.other" style="width: 520px" size="mini"></el-input>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->

        <el-form-item label="其它要求" label-width="105px" style="margin-top: 7px">
          <el-col :span="11">
            <el-input type="textarea" v-model="orderForm.other"></el-input>
          </el-col>
        </el-form-item>

        <el-row style="margin-top: 30px">
          <el-button type="primary" size="mini" @click="submitOrder">提交订单</el-button>
          <el-button type="danger" size="mini" @click="saveDraft" v-if="false">保存草稿</el-button>
        </el-row>

      </el-form>

    </el-drawer>

    <el-drawer
        title="订单详情"
        :visible.sync="editOrderDrawer"
        direction="rtl"
        size="100%"
        :wrapperClosable="false"
        :close-on-press-escape="false"
    >
      <div>
        <span class="title">接单</span>
      </div>
      <el-form ref="orderForm" class="orderForm" label-position="left">
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="单据类型" label-width="105px">
              <el-select :disabled="!isEdit" v-model="orderForm.type" placeholder="请选择" size="mini"
                         style="width: 230px">
                <el-option v-for="(item) in oderTypeList" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="认证项目" label-width="105px">
              <el-select :disabled="!isEdit" v-model="orderForm.project" placeholder="请选择" size="mini"
                         style="width: 230px">
                <el-option v-for="(typeItem, index) in projectList" :label="typeItem.name"
                           :value="typeItem.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="认证机构" label-width="105px">
              <el-select :disabled="!isEdit" v-model="orderForm.organ" placeholder="请选择" size="mini"
                         style="width: 230px">
                <el-option v-for="(typeItem, index) in organList" :label="typeItem.name"
                           :value="typeItem.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="出证时间" label-width="105px">
              <!--              <el-select  :disabled="!isEdit" v-model="orderForm.certificationTime" placeholder="请选择" size="mini" style="width: 230px">-->
              <!--                <el-option v-for="(item) in certificationTimeList" :label="item"-->
              <!--                           :value="item"></el-option>-->
              <!--              </el-select>-->
              <el-input :readonly="!isEdit" v-model="orderForm.certificationTime" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="认证费" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.certificationFee" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="咨询费" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.consultingFee" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年审认证费" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.nextCertificationFee" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年审咨询费" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.nextConsultingFee" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="红包" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.redEnvelope" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员费" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.membershipFee" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="授权费" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.licensingFee" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其它费用" label-width="105px">
              <el-input :readonly="!isEdit" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="协议价" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.agreementPrice" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开票公司" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.invoiceCompany" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="返款金额" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.returnMoney" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="返款日期" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="orderForm.returnDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="返款日期"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="差旅报销" label-width="105px">
              <el-radio :disabled="!isEdit" v-model="orderForm.reimbursementType" label="0">全包</el-radio>
              <el-radio :disabled="!isEdit" v-model="orderForm.reimbursementType" label="1">客户承担</el-radio>
              <el-radio :disabled="!isEdit" v-model="orderForm.reimbursementType" label="2">
                审核由机构承担、辅导由客户承担
              </el-radio>
              <el-radio :disabled="!isEdit" v-model="orderForm.reimbursementType" label="3">其它</el-radio>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="" label-width="105px">
              <el-input :readonly="!isEdit" v-model="orderForm.reimbursementRemark" style="width: 230px" size="mini"
                        :disabled="orderForm.reimbursementType!=3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="其它要求" label-width="105px">
          <el-col :span="12">
            <el-input :readonly="!isEdit" type="textarea" v-model="orderForm.other"></el-input>
          </el-col>
        </el-form-item>

        <el-row>
          <el-button :disabled="!isEdit" type="primary" size="mini" @click="editOrder" class="edit_msg">修改</el-button>
        </el-row>

      </el-form>

      <div style="margin-top: 20px">
        <span class="title">客户信息</span>
      </div>
      <el-form ref="customerForm" class="customerForm" label-position="left">
        <div style="margin-top: 10px">
          <span class="sub_title">基本信息</span>
        </div>
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="客户姓名" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.name" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="联系方式" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.phone" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="邮箱" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.mail" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row style="width: 100%">

          <el-col :span="12">
            <el-form-item label="公司名称" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.company" size="mini" style="width: 90%"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="公司名称-En" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.companyEn" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="注册地址" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.address" style="width: 585px" size="mini"
                        @keyup.enter.native="modifyAddress"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经营地址" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.businessAddress" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="注册地址-En" label-width="105px">

              <el-input :readonly="!isEdit" type="textarea" v-model="customerForm.addressEn"
                        style="width: 585px;margin-top: 8px" size="mini"></el-input>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经营地址-En" label-width="105px">
              <el-input :readonly="!isEdit" type="textarea" v-model="customerForm.businessAddressEn"
                        style="width: 585px;margin-top: 8px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="其它信息" label-width="105px">
          <el-col :span="12">
            <el-input :readonly="!isEdit" type="textarea" v-model="customerForm.other"
                      style="width: 585px;margin-top: 24px"></el-input>
          </el-col>
        </el-form-item>

        <div>
          <span class="sub_title">开票资料</span>
        </div>
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="公司名称" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoiceCompany" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="纳税识别号" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.businessLicenseId" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系电话" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoicePhone" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="财务电话" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoiceFinancialPhone" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="开户银行" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoiceBank" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="银行账号" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoiceAccount" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="注册地址" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoiceAddress" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="邮寄地址" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.invoiceDeliveryAddress" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div>
          <span class="sub_title">营业执照</span>
        </div>
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="公司名称" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.licenceCompany" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="机构代码" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.licenceOrgCode" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="注册资金" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.registeredFund" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="法人" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.legalPerson" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="注册地址" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.licenceAddress" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Address" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.licenceAddressEn" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="经营范围" label-width="105px" style="margin-top: 7px">
          <el-col :span="12">
            <el-input :readonly="!isEdit" type="textarea" v-model="customerForm.licenceScope"
                      style="width: 585px;"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="营业执照" label-width="105px" style="margin-top: 7px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadLicenseFile"
                :http-request="uploadLicenseFile"
                :on-remove="handleRemoveLicense"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="licenseFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(licenseFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>
        <div>
          <span class="sub_title">外包信息</span>
        </div>
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="公司名称" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.outsourceName" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="外包资料" label-width="105px" style="margin-top: 7px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadOutSourceFile"
                :http-request="uploadOutSourceFile"
                :on-remove="handleRemoveOutSource"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="outsourceFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(outsourceFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>
        <div>
          <span class="sub_title">其它</span>
        </div>
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="公司人数" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.staffCount" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="年营业额" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.turnover" style="width: 230px" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品类型" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.productType" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="证书邮寄地址" label-width="105px">
              <el-input :readonly="!isEdit" v-model="customerForm.licenseDeliveryAddress" style="width: 585px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="印刷许可证" label-width="105px" style="margin-top: 7px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadPrintingLicenseFile"
                :http-request="uploadPrintingLicenseFile"
                :on-remove="handleRemovePrintingLicense"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="printingLicenseFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(printingLicenseFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>


        <el-form-item label="贸易登记证" label-width="105px" style="margin-top: 7px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadTradeLicenseFile"
                :http-request="uploadTradeLicenseFile"
                :on-remove="handleRemoveTradeLicense"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="tradeLicenseFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(tradeLicenseFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>

        <el-form-item label="租赁合同" label-width="105px" style="margin-top: 7px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadContractLicenseFile"
                :http-request="uploadContractLicenseFile"
                :on-remove="handleRemoveContractLicense"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="contractLicenseFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(contractLicenseFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>

        <el-row>
          <el-button :disabled="!isEdit" type="primary" size="mini" @click="editCustomer" class="edit_msg">修改
          </el-button>
        </el-row>
      </el-form>

      <div style="margin-top: 20px">
        <span class="title">排期</span>
      </div>
      <el-form ref="scheduleForm" class="scheduleForm" label-position="left">
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="申请资料提交" label-width="105px">
              <el-radio :disabled="!isEdit" v-model="scheduleForm.isSubmitted" label="1">是</el-radio>
              <el-radio :disabled="!isEdit" v-model="scheduleForm.isSubmitted" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="申请资料" label-width="105px" style="margin-top: 7px"
                      v-show="scheduleForm.isSubmitted == 1">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadApplicationFile"
                :http-request="uploadApplicationFile"
                :on-remove="handleRemoveApplication"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="applicationFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(applicationFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="TLA是否签署" label-width="105px">
              <el-radio :disabled="!isEdit" v-model="scheduleForm.isTlaSigned" label="1">是</el-radio>
              <el-radio :disabled="!isEdit" v-model="scheduleForm.isTlaSigned" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="培训时间" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="trainDateList"
                    type="dates"
                    size="mini"
                    value-format="yyyy-MM-dd"
                    placeholder="培训时间"
                />
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="审核时间" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="auditDateList"
                    type="dates"
                    size="mini"
                    value-format="yyyy-MM-dd"
                    placeholder="审核时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="培训老师" label-width="105px">
              <el-select :disabled="!isEdit" v-model="trainTeacherList" placeholder="请选择" size="mini"
                         style="width: 230px"
                         multiple>
                <el-option v-for="(typeItem, index) in teacherList" :label="typeItem['realName']"
                           :value="typeItem['realName']"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>


        <el-form-item label="审核老师" label-width="105px">
          <div>
            <div class="audit_teacher">
              <i class="el-icon-circle-plus-outline" style="font-size: 27px;margin-top: 5px"
                 @click="addAuditTeacher"></i>

              <el-select :disabled="!isEdit" v-model="auditTeachersTempList" placeholder="请选择" size="mini"
                         style="width: 230px"
                         @change="changeAuditTeacher">
                <el-option v-for="(typeItem, index) in builtInAuditTeacherList" :label="typeItem['name']"
                           :value="typeItem['name']"></el-option>
              </el-select>
            </div>
            <div class="audit_parent" v-for="(typeItem, index) in auditTeachersList">
              <el-input :readonly="!isEdit" v-model="typeItem.name" style="width: 230px;margin-right: 20px" size="mini"
                        placeholder="请输入姓名"></el-input>
              <el-input :readonly="!isEdit" v-model="typeItem.phone" style="width: 230px;margin-right: 20px" size="mini"
                        placeholder="请输入联系方式"></el-input>
              <el-input :readonly="!isEdit" v-model="typeItem.mail" style="width: 230px;margin-right: 20px" size="mini"
                        placeholder="请输入邮箱"></el-input>

              <i class="el-icon-remove-outline" style="font-size: 27px"
                 @click="delAuditTeacher(index)"></i>
            </div>
          </div>
        </el-form-item>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="是否完成排期" label-width="105px">
              <el-radio :disabled="!isEdit" v-model="scheduleForm.isFinished" label="1">是</el-radio>
              <el-radio :disabled="!isEdit" v-model="scheduleForm.isFinished" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-button :disabled="!isEdit" type="primary" size="mini" @click="editSchedule" class="edit_msg">修改
          </el-button>
        </el-row>
      </el-form>

      <div style="margin-top: 20px">
        <span class="title">培训</span>
      </div>
      <el-form ref="auditForm" class="scheduleForm" label-position="left">
        <el-row style="width: 100%;height: 350px">
          <el-col :span="6">
            <el-form-item label="门头照" label-width="105px">
              <el-image
                  style="width: 350px; height: 280px"
                  :src="auditForm.companyPhoto"
                  :fit="picFit"></el-image>

              <el-button size="small" type="primary" plain @dblclick.native="downloadFile(auditForm.companyPhoto)"
                         class="download_btn">下载门头照
              </el-button>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="上班打卡时间" label-width="105px">
              <span style="width: 60px">{{ auditForm.signDate }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item label="下班打卡时间" label-width="105px">
              <span style="width: 60px">{{ auditForm.signDate2 }}</span>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="地址" label-width="105px">
              <baidu-map :center="center" :zoom="zoom" @ready="handler" style="width: 520px;height: 300px"></baidu-map>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="培训资料" label-width="105px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadTrainFile"
                :http-request="uploadTrainFile"
                :on-remove="handleRemoveTrain"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="trainFileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button size="small" type="primary" plain @dblclick.native="downloadFiles(trainFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>

        <el-form-item label="咨询合同" label-width="105px">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadContractFile"
                :http-request="uploadContractFile"
                :on-remove="handleRemoveContract"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="contractFileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button size="small" type="primary" plain @dblclick.native="downloadFiles(contractFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>

        <el-form-item label="NC问题点" label-width="105px">
          <div>
            <i class="el-icon-circle-plus-outline" style="font-size: 27px;margin-top: 5px"
               @click="addNC"></i>
            <div class="nc-item" v-for="(typeItem, index) in ncList">
              <span>{{ '问题' + (index + 1) }}</span>
              <el-upload
                  style="margin-left: 25px; width: 600px"
                  class="upload-component"
                  action="string"
                  :http-request="(e) => uploadNcFile(e, index)"
                  :on-remove="(e1, e2) => handleRemoveNc(e1, e2, index)"
                  :on-preview="handlePreviewCertification"
                  multiple
                  :limit="30"
                  :file-list="typeItem.fileList">
                <el-button size="small" type="primary">点击上传</el-button>

              </el-upload>
              <el-button size="small" type="primary" plain @dblclick.native="downloadFiles(typeItem.fileList)"
                         class="download_btn">点击下载
              </el-button>
              <span style="margin-left: 25px;margin-right: 25px">是否解决</span>
              <el-radio v-model="typeItem.isResolved" label="1">是</el-radio>
              <el-radio v-model="typeItem.isResolved" label="0">否</el-radio>

              <span v-show="typeItem.isResolved == 1" style="margin-right: 10px">解决时间</span>
              <el-date-picker
                  style="margin-right: 10px"
                  v-show="typeItem.isResolved == 1"
                  v-model="typeItem.resolvedDate"
                  size="mini"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="解决时间"
              />
              <i class="el-icon-remove-outline" style="font-size: 20px"
                 @click="delNc(index)"></i>
            </div>
          </div>
        </el-form-item>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="审核报告提交" label-width="105px">
              <el-radio v-model="auditForm.isAuditReportSubmitted" label="1">是</el-radio>
              <el-radio v-model="auditForm.isAuditReportSubmitted" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="审核结果" label-width="105px">
              <el-radio v-model="auditForm.isPassed" label="1">通过</el-radio>
              <el-radio v-model="auditForm.isPassed" label="0">未通过</el-radio>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="未通过原因" label-width="105px" v-show="auditForm.isPassed==0">
              <el-input v-model="auditForm.failureReason" style="width: 520px;margin-right: 20px" size="mini"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div>
          <el-form-item label="认证费全款" label-width="105px">
            <el-radio v-model="auditForm.isCertificationFullPayment" label='1'>是</el-radio>
            <el-radio v-model="auditForm.isCertificationFullPayment" label='0'>否</el-radio>
            <span style="margin-left: 25px;margin-right: 25px;color: blue"
                  @click="paymentRecordList('0')">咨询记录</span>
            <div style="width: 100%;">
              <el-date-picker
                  style="width: 230px"
                  v-model="auditForm.cerPayDate"
                  size="mini"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="认证费付款时间"
              />
            </div>
          </el-form-item>

          <el-form-item label="新增咨询记录" label-width="105px">
            <el-input style="width: 300px" v-model="conRecord" size="mini" type="textarea"></el-input>
            <el-button type="primary" size="mini" style="margin-left: 25px;margin-right: 25px;" @click="addConRecord">
              新增记录
            </el-button>
          </el-form-item>
        </div>

        <div>
          <el-form-item label="咨询费全款" label-width="105px">
            <el-radio v-model="auditForm.isConsultingFullPayment" label='1'>是</el-radio>
            <el-radio v-model="auditForm.isConsultingFullPayment" label='0'>否</el-radio>
            <span style="margin-left: 25px;margin-right: 25px;color: blue"
                  @click="paymentRecordList('1')">咨询记录</span>
            <div style="width: 100%;">
              <el-date-picker
                  style="width: 230px"
                  v-model="auditForm.conPayDate"
                  size="mini"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="咨询费付款时间"
              />
            </div>
          </el-form-item>

          <el-form-item label="新增咨询记录" label-width="105px">
            <el-input style="width: 300px" v-model="cerRecord" size="mini" type="textarea"></el-input>
            <el-button type="primary" size="mini" style="margin-left: 25px;margin-right: 25px;" @click="addCerRecord">
              新增记录
            </el-button>

            <el-switch
                v-model="isCredible"
                active-text="信用良好"
                inactive-text="信用不好"
                @change="changeCredit"
            >
            </el-switch>
          </el-form-item>
        </div>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="是否完成辅导" label-width="105px">
              <el-radio v-model="auditForm.isTrainFinished" label="1">是</el-radio>
              <el-radio v-model="auditForm.isTrainFinished" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="是否完成审核" label-width="105px">
              <el-radio v-model="auditForm.isAuditFinished" label="1">是</el-radio>
              <el-radio v-model="auditForm.isAuditFinished" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>


        <el-form-item label="报销凭证" label-width="105px">
          <div>
            <!--            <i class="el-icon-circle-plus-outline" style="font-size: 27px;margin-top: 5px"-->
            <!--               @click="addReceipt" v-if="false"></i>-->
            <div class="nc-item" v-for="(typeItem, index) in receiptList" style="margin-top: 10px;margin-bottom: 10px">
              <span style="width: 120px;font-weight: bold">{{ typeItem.title }}</span>
              <el-upload
                  style="margin-left: 5px; width: 300px"
                  class="upload-component"
                  action="string"
                  :http-request="(e) => uploadReceiptFile(e, index)"
                  :on-remove="(e1, e2) => handleRemoveReceipt(e1, e2, index)"
                  :on-preview="handlePreviewCertification"
                  multiple
                  :limit="30"
                  :file-list="typeItem.fileList">
                <el-button size="small" type="primary">点击上传</el-button>

              </el-upload>
              <div class="receipt_child" style="margin-right: 10px">
                <div class="common_receipt">
                  <span>说明: </span>
                  <el-input v-model="typeItem.detail" size="mini"
                            style="width: 350px;margin-right: 10px;margin-left: 5px"
                            placeholder="报销说明" type="textarea"></el-input>
                  <span>总金额:</span>
                  <el-input v-model="typeItem.receiptMoney" size="mini"
                            style="width: 100px;margin-left: 5px"
                            placeholder="报销总金额"></el-input>
                </div>

                <div class="extra_receipt" v-show="typeItem.title.search('自驾') != -1">
                  <span>起始:</span>
                  <el-input v-model="typeItem.startKilometers" size="mini"
                            style="width: 100px;margin-right: 10px;margin-left: 5px"
                            placeholder="起始公里数"></el-input>
                  <span>终点:</span>
                  <el-input v-model="typeItem.endKilometers" size="mini"
                            style="width: 100px;margin-right: 10px;margin-left: 5px"
                            placeholder="结束公里数"></el-input>
                  <span>补贴:</span>
                  <el-input v-model="typeItem.drivingFee" size="mini"
                            style="width: 100px;margin-right: 10px;margin-left: 5px"
                            placeholder="补贴费用"></el-input>
                  <span>停车:</span>
                  <el-input v-model="typeItem.parkingFee" size="mini"
                            style="width: 100px;margin-right: 10px;margin-left: 5px"
                            placeholder="停车费"></el-input>
                  <span>过桥:</span>
                  <el-input v-model="typeItem.bridgeFee" size="mini"
                            style="width: 100px;margin-left: 5px"
                            placeholder="过桥费"></el-input>
                </div>
              </div>

              <!--              <i class="el-icon-remove-outline" style="font-size: 20px"-->
              <!--                 @click="delReceipt(index)"></i>-->
            </div>
          </div>
        </el-form-item>

        <el-row style="width: 100%">
          <el-form-item label="报销总额" label-width="105px">
            <el-col :span="4">
              <el-input v-model="auditForm.receiptTotal" size="mini" :disabled="true"></el-input>
            </el-col>
          </el-form-item>
        </el-row>


        <div>
          <el-form-item label="是否完成报销校验" label-width="105px">
            <el-radio v-model="auditForm.isVerified" label='1'>是</el-radio>
            <el-radio v-model="auditForm.isVerified" label='0'>否</el-radio>

            <div style="width: 100%;">
              <el-date-picker
                  style="width: 230px"
                  v-model="auditForm.verificationDate"
                  size="mini"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="校验时间"
              />
            </div>
          </el-form-item>

          <el-form-item label="新增咨询记录" label-width="105px">
            <el-input style="width: 300px" v-model="conRecord" size="mini" type="textarea"></el-input>
            <el-button type="primary" size="mini" style="margin-left: 25px;margin-right: 25px;" @click="addConRecord">
              新增记录
            </el-button>
          </el-form-item>
        </div>

        <el-row>
          <el-button type="primary" size="mini" @click="editAudit" class="edit_msg">修改</el-button>
        </el-row>

      </el-form>
      <div style="margin-top: 20px">
        <span class="title">出证</span>
      </div>
      <el-form ref="certificateForm" class="scheduleForm" label-position="left">
        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="报告审批时间" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.reportApprovalDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="报告审批时间"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="是否出证" label-width="105px">
              <el-radio :disabled="!isEdit" v-model="certificateForm.isCertificateIssued" label="1">是</el-radio>
              <el-radio :disabled="!isEdit" v-model="certificateForm.isCertificateIssued" label="0">否</el-radio>
              <el-date-picker
                  style="width: 230px"
                  :disabled="!isEdit"
                  v-model="certificateForm.licenseDate"
                  type="date"
                  size="mini"
                  value-format="yyyy-MM-dd"
                  placeholder="出证时间"
                  v-show="certificateForm.isCertificateIssued == 1"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="证书" label-width="105px" style="margin-top: 7px"
                      v-show="certificateForm.isCertificateIssued == 1">
          <el-col :span="12">
            <el-upload
                class="upload-component"
                action="string"
                ref="uploadCertificationFile"
                :http-request="uploadCertificationFile"
                :on-remove="handleRemoveCertification"
                :on-preview="handlePreviewCertification"
                multiple
                :limit="30"
                :file-list="certificateFileList">
              <el-button :disabled="!isEdit" size="small" type="primary">点击上传</el-button>

            </el-upload>
            <el-button :disabled="!isEdit" size="small" type="primary" plain
                       @dblclick.native="downloadFiles(certificateFileList)"
                       class="download_btn">点击下载
            </el-button>
          </el-col>
        </el-form-item>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="电子证书时间" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.elecCertificateDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="电子证书发送时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="纸质证书时间" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.paperCertificateDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="纸质证书发送时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="快递单号" label-width="105px">
              <el-input :readonly="!isEdit" v-model="certificateForm.certificateExpressNo" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="咨询费电发票" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.consultingEInvoiceDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="咨询费电子发票发送时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="咨询费纸发票" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.consultingPInvoiceDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="咨询费纸质发票发送时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="快递单号" label-width="105px">
              <el-input :readonly="!isEdit" v-model="certificateForm.consultingExpressNo" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row style="width: 100%">
          <el-col :span="6">
            <el-form-item label="认证费电发票" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.certificationEInvoiceDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="认证费电子发票发送时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="认证费纸发票" label-width="105px">
              <div style="width: 100%;">
                <el-date-picker
                    style="width: 230px"
                    :disabled="!isEdit"
                    v-model="certificateForm.certificationPInvoiceDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="认证费纸质发票发送时间"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="快递单号" label-width="105px">
              <el-input :readonly="!isEdit" v-model="certificateForm.certificationExpressNo" style="width: 230px"
                        size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-button :disabled="!isEdit" type="primary" size="mini" @click="editLicense" class="edit_msg">修改
          </el-button>
        </el-row>

      </el-form>

      <el-row style="width: 100%" justify="center align=" center type="flex">
        <el-button :disabled="!isEdit" type="primary" size="mini" @click="finishOder" class="edit_bottom"
                   style="margin-right: 20px"
                   v-if="this.orderForm.orderStatus != '7' && this.orderForm.orderStatus != '6'">结单
        </el-button>
        <el-button :disabled="!isEdit" type="danger" size="mini" @click="backOder" class="edit_bottom"
                   style="margin-left: 20px"
                   v-if="this.orderForm.orderStatus != '7' && this.orderForm.orderStatus != '6'">撤单
        </el-button>

        <el-button :disabled="!isEdit" type="primary" size="mini" @click="revokeFinishOder" class="edit_bottom"
                   style="margin-right: 20px"
                   v-if="this.orderForm.orderStatus == '6'">反结单
        </el-button>
        <el-button :disabled="!isEdit" type="danger" size="mini" @click="revokeBackOder" class="edit_bottom"
                   style="margin-left: 20px"
                   v-if="this.orderForm.orderStatus == '7'">反撤单
        </el-button>
      </el-row>

    </el-drawer>

    <el-drawer
        title="文件预览"
        :visible.sync="filePreViewDrawer"
        direction="rtl"
        size="100%"
        :wrapperClosable="false"
        :close-on-press-escape="false"
        :modal="false"
        @close="filePreViewCLose"
    >
      <vue-office-docx :src="curFileUrl" v-if="curFileUrl.toLowerCase().endsWith('docx')"/>
      <vue-office-excel :src="curFileUrl" v-if="curFileUrl.toLowerCase().endsWith('xlsx')"/>
      <vue-office-pdf :src="curFileUrl" v-if="curFileUrl.toLowerCase().endsWith('pdf')"/>
      <el-image :src="curFileUrl" v-if="curFileUrl.toLowerCase().endsWith('jpg') || curFileUrl.toLowerCase().endsWith('jpeg')
       || curFileUrl.toLowerCase().endsWith('png') || curFileUrl.toLowerCase().endsWith('webp') ||
       curFileUrl.toLowerCase().endsWith('gif') || curFileUrl.toLowerCase().endsWith('jpe')"
      />
    </el-drawer>


    <el-dialog :title="payMentTitle" :visible.sync="recordDialog" center width="55%">

      <div style="margin-left: 25px">
        <el-table
            :row-style="{height: '30px'}"
            :cell-style="{padding: '0px'}"
            ref="multipleTable"
            :data="recordList"
            tooltip-effect="dark"
            style="width: 100%;font-size: 10px;text-align: center"
        >
          <el-table-column
              align="center"
              width="500"
              label="原因"
          >
            <template v-slot="scope">
                <span class="order_item">{{
                    scope.row.reason
                  }}</span>
            </template>

          </el-table-column>
          <el-table-column
              align="center"
              label="日期"
              width="180">
            <template v-slot="scope">
                <span class="order_item">{{
                    scope.row.createTime
                  }}</span>
            </template>
          </el-table-column>


          <el-table-column
              align="center"
              label="操作"
              width="120">
            <template v-slot="scope">
                   <span class="order_item" style="color: blue" @click="delPaymentRecord(scope.row)">{{
                       '删除'
                     }}</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeRecordDialog" size="mini">关闭</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'

//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'

//引入VueOfficePdf组件
import VueOfficePdf from '@vue-office/pdf'

import Element from "_element-ui@2.15.14@element-ui";

const OSS = require('ali-oss');

// 配置OSS参数
let client = new OSS({
  // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
  region: "oss-cn-nanjing",
  // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
  accessKeyId: "STS.NUjWuPH4BUtggK5aYsHXRqgHsck11",
  accessKeySecret: "6kKof3c3gmk3pnhF7sB7rHdAUqyBkituK34V4nYZNN47t2",
  stsToken: "2CAISgAN1q6Ft5B2yfSjIr5bfHM/kpetj4raMSgRPQvXMdVN1dp7bIiTz2IHhMeHgdsAuAXsPs3n2BS7PgblrpiTIJff0gvAYPB99Y9W9gWHao/M4yFxPSSc0c/LI3OaLjKm9tewCryLT7GwU/OpbE++2U0X6LDmdDKkckW4OJmS8/BOZcgWWQ/KClgjA8xNdDN/tOgQN3baKYz8UHjQj3HXEVBjtydllGp78t7f+MCH7QfEh1CIv41mro/qcJ+/dJsubtUtT9q81+x7bO/F1yVZ718I1t8v1PQVoG+W5o3GWgcKukzbCYeOrI0zdj0eT7MhBqtJoML7kfBFoeHJn+z1sU0VZb0LD3+PFd7/mJCUQrn3b8xBZ7b6IHTE1JWCLIGwrw4/fWKYg9Gy6wqsQ5UZNGvPKlmyQsfPkDhBYlK0xB3q++jvXc7AK/k8YRhus2I6z0ENa9qSwAvoUwJJUVBflCeKWYQNCDdneBhsHI3eZqY4T30w5N2u00S8OSMIjZbcu9SWD5sagAFaYB6vUU7mvj5+xoo3MuHWsC1rrKOo7jxm8ER+5IPG36O5jzS1ysFSIRyyWzfAvSgDBkKzMRMWOIU0isu/fBGLqDtLKvkoh4ohdtYTdS8PVswzCjL7Wd0zn8ucT/HVWFFH6+0TcKnjkqr8CfNP87qj51zNDuKbX7dRV7SuBxcrxyAA",
  bucket: "teacher-attendance"
});


const statusOptions = ['待排期', '待辅导', '待审核', '待出证', '已出证', '已结单', '已撤单'];
export default {
  components: {
    VueOfficeDocx,
    VueOfficeExcel,
    VueOfficePdf
  },
  name: "OrderList",
  data() {
    return {
      isCredible: true,
      payMentTitle: '咨询记录',
      recordDialog: false,
      recordList: [],
      conRecord: undefined,
      cerRecord: undefined,
      // 定位位置
      center: {lng: 0, lat: 0},
      // 地图放大等级
      zoom: 3,
      picFit: 'contain',
      checkAllTime: false,
      timeList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      checkedTime: ['1'],
      checkAllStatus: true,
      checkedStatus: ['待排期', '待辅导', '待审核', '待出证', '已出证', '已结单', '已撤单'],
      statusList: statusOptions,
      statusIndeterminate: true,
      newOrderDrawer: false,
      editOrderDrawer: false,
      filePreViewDrawer: false,
      pager: {
        curPage: 0,
        pageSize: 20,
        status: [],
        organ: undefined,
        project: undefined,
        trainTeacher: undefined,
        customer: undefined,
        orderDate: undefined,
        trainDate: undefined,
        userName: undefined,
        isVerified: undefined,
        verificationDateList: undefined,
        allDate: false,
        curDateList: undefined
      },
      verificationList: [{name: '是', value: '1'}, {name: '否', value: '0'}],
      organList: [],
      projectList: [],
      teacherList: [],
      builtInAuditTeacherList: [],
      orderListData: [],
      orderSelection: [],
      orderTotalCount: 0,

      //接单
      oderTypeList: ['初审', '监督审核', '复评换证','再认证'],
      certificationTimeList: ['10', '20', '30'],
      orderForm: {
        id: undefined,
        type: '初审',
        project: undefined,
        organ: undefined,
        certificationFee: undefined,
        consultingFee: undefined,
        nextCertificationFee: undefined,
        nextConsultingFee: undefined,
        redEnvelope: undefined,
        membershipFee: undefined,
        licensingFee: undefined,
        certificationTime: undefined,
        reimbursementType: '0',
        reimbursementRemark: undefined,
        other: undefined,
        operatorId: undefined,
        orderStatus: undefined,
        returnMoney: undefined,
        agreementPrice: undefined,
        invoiceCompany: undefined,
        returnDate: undefined,

      },
      showDel: false,
      showVerify: false,
      isEdit: undefined,
      customerForm: {
        //基本信息
        name: undefined,
        company: undefined,
        companyEn: undefined,
        phone: undefined,
        mail: undefined,
        address: undefined,
        addressEn: undefined,
        other: undefined,
        businessAddress: undefined,
        businessAddressEn: undefined,
        //开票
        invoiceCompany: undefined,
        invoiceAddress: undefined,
        invoiceAddressEn: undefined,
        businessLicenseId: undefined,
        invoicePhone: undefined,
        invoiceBank: undefined,
        invoiceAccount: undefined,
        invoiceDeliveryAddress: undefined,
        invoiceFinancialPhone: undefined,
        //营业执照
        licenceCompany: undefined,
        licenceOrgCode: undefined,
        registeredFund: undefined,
        licenceAddress: undefined,
        licenceAddressEn: undefined,
        legalPerson: undefined,
        licenceScope: undefined,
        licenseFiles: undefined,

        staffCount: undefined,
        turnover: undefined,
        productType: undefined,
        licenseDeliveryAddress: undefined,

        printingLicense: undefined,
        tradeCertificate: undefined,
        leaseContract: undefined,

        orderId: undefined,
        operatorId: undefined,
        outsourceName: undefined,
        outsourceDocs: undefined
      },
      licenseFileList: [],
      outsourceFileList: [],
      printingLicenseFileList: [],
      tradeLicenseFileList: [],
      contractLicenseFileList: [],
      //排期
      scheduleForm: {
        id: undefined,
        isSubmitted: undefined,
        applicationDocs: undefined,
        isTlaSigned: undefined,
        trainDate: undefined,
        auditDate: undefined,
        trainTeachers: undefined,
        auditTeachers: undefined,
        orderId: undefined,
        operatorId: undefined,
        isFinished: undefined
      },
      trainDateList: undefined,
      auditDateList: undefined,
      trainTeacherList: undefined,
      auditTeachersTempList: undefined,
      applicationFileList: [],
      auditTeachersList: [],

      //培训
      auditForm: {
        companyPhoto: undefined,
        signDate: undefined,
        signDate2: undefined,
        signAddress: undefined,
        longitude: undefined,
        latitude: undefined,
        trainDocs: undefined,
        isPassed: undefined,
        failureReason: undefined,
        isCertificationFullPayment: undefined,
        isConsultingFullPayment: undefined,
        consultingContract: undefined,
        isAuditReportSubmitted: undefined,
        operatorId: undefined,
        orderId: undefined,
        cerPayDate: undefined,
        conPayDate: undefined,
        receiptTotal: undefined,
        isTrainFinished: undefined,
        isAuditFinished: undefined,
        isVerified: undefined,
        verificationDate: undefined
      },
      trainFileList: [],
      contractFileList: [],
      ncList: [],
      receiptList: [],

      //出证
      certificateForm: {
        id: undefined,
        reportApprovalDate: undefined,
        isCertificateIssued: undefined,
        certificateDocs: undefined,
        certificationEInvoiceDate: undefined,
        certificationPInvoiceDate: undefined,
        consultingEInvoiceDate: undefined,
        consultingPInvoiceDate: undefined,
        elecCertificateDate: undefined,
        paperCertificateDate: undefined,
        certificationExpressNo: undefined,
        consultingExpressNo: undefined,
        certificateExpressNo: undefined,
        orderId: undefined,
        operatorId: undefined,
        licenseDate: undefined
      },

      certificateFileList: [],
      curUser: undefined,
      curFileUrl: ''
    }
  },
  methods: {
    logout() {
      this.$confirm('确认退出当前账户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        width: '200px'
      }).then(() => {
        console.log('logout')
        this.$store.commit('SET_TOKEN', '')
        this.$store.commit('SET_USERNAME', '')
        this.$router.replace({
          name: 'login'
        })
      }).catch(() => {

      });

    },
    delPaymentRecord(item) {
      this.$axios.post('/order/delPaymentRecord', item).then(res => {
        console.log('/order/delPaymentRecord')
        console.log(res)
        this.paymentRecordList(this.curRecordType)
      }).catch((error) => {
        console.error(error)
      })
    },

    addPaymentRecord(paymentRecord) {
      this.$axios.post('/order/addPaymentRecord', paymentRecord).then(res => {
        console.log('/order/addPaymentRecord')
        console.log(res)
      }).catch((error) => {
        console.error(error)
      })
    },

    paymentRecordList(type) {
      if (type == '0') {
        this.payMentTitle = '认证费咨询记录'
      } else if (type == '1') {
        this.payMentTitle = '咨询费咨询记录'
      }
      this.curRecordType = type
      let item = {type: type, orderId: this.orderForm.id}
      this.$axios.post('/order/paymentRecordList', item).then(res => {
        console.log('/order/paymentRecordList')
        console.log(res)
        this.recordDialog = true
        this.recordList = res.data.data
      }).catch((error) => {
        console.error(error)
      })
    },

    addConRecord() {
      this.addPaymentRecord({
        type: '0',
        reason: this.conRecord,
        operatorId: this.curUser.id,
        orderId: this.orderForm.id
      })
    },

    addCerRecord() {
      this.addPaymentRecord({
        type: '1',
        reason: this.cerRecord,
        operatorId: this.curUser.id,
        orderId: this.orderForm.id
      })
    },

    closeRecordDialog() {
      this.recordDialog = false
    },

    handler({BMap, map}) {
      console.log(BMap, map)
      // 经度
      // this.center.lng = 116.404
      // // 纬度
      // this.center.lat = 39.915
      // // 地图放大等级
      // this.zoom = 15
    },
    changeAuditTeacher(type) {
      console.log(`当前老师: ${type}`);
      this.auditTeachersList.push({name: type, phone: undefined, mail: undefined})
      console.info(this.auditTeachersTempList)
      this.auditTeachersTempList = undefined
    },

    clearVerification() {
      console.log(`clear data`);
      this.pager.curPage = 0
      this.getOrderList()
    },
    changeVerification(type) {
      console.log(`当前机构: ${type}`);
      this.pager.curPage = 0
      this.getOrderList()
    },

    clearOrgan() {
      console.log(`clear data`);
      this.pager.curPage = 0
      this.getOrderList()
    },
    changeOrgan(type) {
      console.log(`当前机构: ${type}`);
      this.pager.curPage = 0
      this.getOrderList()
    },

    clearProject() {
      console.log(`clear data`);
      this.pager.curPage = 0
      this.getOrderList()
    },
    changeProject(type) {
      console.log(`当前项目: ${type}`);
      this.pager.curPage = 0
      this.getOrderList()
    },

    clearTeacher() {
      console.log(`clear data`);
      this.pager.curPage = 0
      this.getOrderList()
    },
    changeTeacher(type) {
      console.log(`当前项目: ${type}`);
      this.pager.curPage = 0
      this.getOrderList()
    },

    customerEnter() {
      this.pager.curPage = 0
      this.getOrderList()
    },

    modifyAddress() {
      let address = this.customerForm.address
      this.customerForm.invoiceAddress = address
      this.customerForm.licenceAddress = address
    },

    handleCheckAllTimeChange(val) {
      console.log('handleCheckAllTimeChange : ' + val)
      this.checkAllTime = val
    },

    handleCheckedTimeChange(value) {
      console.log('handleCheckedTimeChange : ' + value)
      this.checkedTime = value
    },

    handleCheckAllStatusChange(val) {
      this.checkedStatus = val ? statusOptions : [];
      this.statusIndeterminate = false;
      if (val == true) {
        this.pager.status.length = 0
        this.pager.status.push('-1');
        this.getOrderList()
      }
    },

    handleCheckedStatusChange(value) {
      let checkedCount = value.length;
      this.checkAllStatus = checkedCount === this.statusList.length;
      this.statusIndeterminate = checkedCount > 0 && checkedCount < this.statusList.length;
      if (this.checkAllStatus == true) {
        this.pager.status.length = 0
        this.pager.status.push('-1');
        this.getOrderList()
        return
      }
      this.pager.status.length = 0
      this.pager.status = value
      this.getOrderList()
    },

    orderClick(row) {
      console.info(row.newOrder.id)
      this.getOrderDetail(row.newOrder.id)
    },

    handleOrderSelectionChange(val) {
      this.orderSelection = val
    },

    handleOrderSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pager.pageSize = val
      this.getOrderList()
    },
    handleCurrentOrderChange(val) {
      console.log(`当前页: ${val}`);
      this.pager.curPage = val
      this.getOrderList()
    },
    handlePreOrder(val) {
      console.log(`每页 ${val} 条`);
      this.pager.curPage = val
      this.getOrderList()
    },
    handleNextOrder(val) {
      console.log(`当前页: ${val}`);
      this.pager.curPage = val
      this.getOrderList()
    },

    getOrderList() {
      let userName = localStorage.getItem("username");
      this.pager.userName = userName
      this.pager.allDate = this.checkAllTime
      this.pager.curDateList = this.checkedTime
      this.$axios.post('/order/orderList', this.pager).then(res => {
        console.log('/order/orderList')
        console.log(res)
        this.orderListData = res.data.data.records
        this.orderTotalCount = res.data.data.total
      }).catch((error) => {
        console.error(error)
      })
    },

    getOrganList() {
      this.$axios.post('/order/organList').then(res => {
        console.log('/order/organList')
        console.log(res)
        this.organList = res.data.data
      }).catch((error) => {
        console.error(error)
      })
    },

    getProjectList() {
      this.$axios.post('/order/projectList').then(res => {
        console.log('/order/projectList')
        console.log(res)
        this.projectList = res.data.data
      }).catch((error) => {
        console.error(error)
      })
    },

    getTrainTeacherList() {
      this.$axios.post('/order/trainTeacherList').then(res => {
        console.log('/order/trainTeacherList')
        console.log(res)
        this.teacherList = res.data.data
      }).catch((error) => {
        console.error(error)
      })
    },

    getAuditTeacherList() {
      this.$axios.post('/order/auditTeacherList').then(res => {
        console.log('/order/auditTeacherList')
        console.log(res)
        this.builtInAuditTeacherList = res.data.data
      }).catch((error) => {
        console.error(error)
      })
    },

    getUserInfo() {
      let userName = localStorage.getItem("username");
      let user = {userName: userName}
      this.$axios.post('/user/userInfo', user).then(res => {
        console.log('/user/userInfo')
        console.log(res)
        this.curUser = res.data.data
        if (this.curUser.role.search('2') != -1) {
          this.showDel = true
        } else {
          this.showDel = false
        }

        if (this.curUser.role.search('4') != -1) {
          this.showVerify = true
        } else {
          this.showVerify = false
        }
      }).catch((error) => {
        console.error(error)
      })
    },

    getOrderDetail(orderId) {
      let userName = localStorage.getItem("username");
      let param = {orderId: orderId, userName: userName}
      this.$axios.post('/order/orderDetail', param).then(res => {
        console.log('/order/orderDetail')
        console.log(res)
        this.editOrderDrawer = true
        this.orderForm = res.data.data.newOrder
        let customerDto = res.data.data.customerDto
        this.customerForm = customerDto.customer
        this.isCredible = (customerDto.customer.goodCustomer == 1)
        this.isEdit = res.data.data.canEdit
        console.info(this.isEdit)

        this.licenseFileList = customerDto.licenseFiles
        this.outsourceFileList = customerDto.ourSourceFiles
        this.printingLicenseFileList = customerDto.printingLicense
        this.tradeLicenseFileList = customerDto.tradeCertificate
        this.contractLicenseFileList = customerDto.leaseContract

        if (this.licenseFileList == undefined || this.licenseFileList == null) {
          this.licenseFileList = []
        }

        if (this.outsourceFileList == undefined || this.outsourceFileList == null) {
          this.outsourceFileList = []
        }

        if (this.printingLicenseFileList == undefined || this.printingLicenseFileList == null) {
          this.printingLicenseFileList = []
        }

        if (this.tradeLicenseFileList == undefined || this.tradeLicenseFileList == null) {
          this.tradeLicenseFileList = []
        }

        if (this.contractLicenseFileList == undefined || this.contractLicenseFileList == null) {
          this.contractLicenseFileList = []
        }

        let scheduleDto = res.data.data.scheduleDto
        this.scheduleForm = scheduleDto.schedule

        this.applicationFileList = scheduleDto.applicationFiles
        this.auditTeachersList = scheduleDto.auditTeacherList

        this.trainTeacherList = scheduleDto.trainTeacherList
        this.trainDateList = scheduleDto.trainDateList
        this.auditDateList = scheduleDto.auditDateList

        if (this.applicationFileList == undefined || this.applicationFileList == null) {
          this.applicationFileList = []
        }

        if (this.auditTeachersList == undefined || this.auditTeachersList == null) {
          this.auditTeachersList = []
        }

        if (this.trainTeacherList == undefined || this.trainTeacherList == null) {
          this.trainTeacherList = []
        }

        if (this.trainDateList == undefined || this.trainDateList == null) {
          this.trainDateList = []
        }

        if (this.auditDateList == undefined || this.auditDateList == null) {
          this.auditDateList = []
        }

        let auditDto = res.data.data.auditDto
        this.auditForm = auditDto.audit

        this.center.lng = this.auditForm.longitude
        // 纬度
        this.center.lat = this.auditForm.latitude
        // 地图放大等级
        this.zoom = 16


        this.trainFileList = auditDto.trainFileList
        this.contractFileList = auditDto.contractFileList

        if (this.trainFileList == undefined || this.trainFileList == null) {
          this.trainFileList = []
        }

        if (this.contractFileList == undefined || this.contractFileList == null) {
          this.contractFileList = []
        }


        let ncProblemDtoList = auditDto.ncProblemDtoList
        this.ncList = []
        if (ncProblemDtoList != undefined && ncProblemDtoList != null) {
          for (let i = 0; i < ncProblemDtoList.length; i++) {
            let ncProblemDto = ncProblemDtoList[i]
            let nc = ncProblemDto.ncProblem
            let ncFileList = ncProblemDto.ncFileList
            nc.fileList = ncFileList
            if (nc.fileList == undefined || nc.fileList == null) {
              nc.fileList = []
            }
            this.ncList.push(nc)
          }
        }

        let receiptDtoList = auditDto.receiptDtoList
        this.receiptList = []
        if (receiptDtoList != undefined && receiptDtoList != null) {
          for (let i = 0; i < receiptDtoList.length; i++) {
            let receiptDto = receiptDtoList[i]
            let receipt = receiptDto.receipt
            let receiptFileList = receiptDto.receiptFileList
            receipt.fileList = receiptFileList
            if (receipt.fileList == undefined || receipt.fileList == null) {
              receipt.fileList = []
            }
            this.receiptList.push(receipt)
          }
        }


        let licenseDto = res.data.data.licenseDto
        this.certificateForm = licenseDto.license
        this.certificateFileList = licenseDto.certificateDocs
        if (this.certificateFileList == undefined || this.certificateFileList == null) {
          this.certificateFileList = []
        }

      }).catch((error) => {
        console.error(error)
      })
    },

    changeCredit(value) {
      console.info(value)
      this.setCustomerCredit()
    },

    filterList() {
      this.pager.curPage = 0
      this.getOrderList()
    },

    resetFilter() {
      this.pager = {
        curPage: 1,
        pageSize: 20,
        status: [],
        organ: undefined,
        project: undefined,
        trainTeacher: undefined,
        customer: undefined,
        orderDate: undefined,
        trainDate: undefined,
        isVerified: undefined,
        verificationDateList: undefined
      }
      this.getOrderList()
    },

    newOrder() {
      this.orderForm = {
        id: undefined,
        type: '初审',
        project: undefined,
        organ: undefined,
        certificationFee: undefined,
        consultingFee: undefined,
        nextCertificationFee: undefined,
        nextConsultingFee: undefined,
        redEnvelope: undefined,
        membershipFee: undefined,
        licensingFee: undefined,
        certificationTime: undefined,
        reimbursementType: '0',
        reimbursementRemark: undefined,
        other: undefined,
        operatorId: undefined,
        orderStatus: undefined,
        returnMoney: undefined,
        agreementPrice: undefined,
        invoiceCompany: undefined,
        returnDate: undefined
      },
          this.customerForm = {
            //基本信息
            name: undefined,
            company: undefined,
            companyEn: undefined,
            phone: undefined,
            mail: undefined,
            address: undefined,
            addressEn: undefined,
            other: undefined,
            businessAddress: undefined,
            businessAddressEn: undefined,
            //开票
            invoiceCompany: undefined,
            invoiceAddress: undefined,
            invoiceAddressEn: undefined,
            businessLicenseId: undefined,
            invoicePhone: undefined,
            invoiceBank: undefined,
            invoiceAccount: undefined,
            invoiceDeliveryAddress: undefined,
            invoiceFinancialPhone: undefined,
            //营业执照
            licenceCompany: undefined,
            licenceOrgCode: undefined,
            registeredFund: undefined,
            licenceAddress: undefined,
            licenceAddressEn: undefined,
            legalPerson: undefined,
            licenceScope: undefined,
            licenseFiles: undefined,

            staffCount: undefined,
            turnover: undefined,
            productType: undefined,
            licenseDeliveryAddress: undefined,

            printingLicense: undefined,
            tradeCertificate: undefined,
            leaseContract: undefined,

            orderId: undefined,
            operatorId: undefined,
            outsourceName: undefined,
            outsourceDocs: undefined
          },
          this.newOrderDrawer = true
    },

    exportOrder() {

    },

    sortOrder() {

    },

    delOrder() {
      if (this.orderSelection.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择单据'
        })
        return
      }
      let ids = []
      for (let i = 0; i < this.orderSelection.length; i++) {
        let orderDto = this.orderSelection[i]
        ids.push(orderDto.newOrder.id)
      }
      this.delOrderDilog(ids)
    },

    delOrderDilog(ids) {
      this.$confirm('此操作将删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        width: '200px'
      }).then(() => {
        this.$axios.post('/order/delOrder', ids).then(res => {
          console.log(res)
          this.pager.curPage = 0
          this.getOrderList()
          this.$message({
            type: 'info',
            message: '删除成功'
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    setCustomerCredit() {
      let param = {
        orderId: this.orderForm.id,
        operatorId: this.curUser.id,
        goodCustomer: this.isCredible ? 1 : 0
      }
      this.$axios.post('/order/setGoodCustomer', param).then(res => {
        console.log('/order/setGoodCustomer')
        console.log(res)
        Element.Message.success(res.data.data, {duration: 3000})
      }).catch((error) => {
        console.error(error)
      })
    },

    submitOrder() {
      this.orderForm.operatorId = this.curUser.id
      this.customerForm.operatorId = this.curUser.id
      let newOrder = {newOrder: this.orderForm, customer: this.customerForm}
      this.$axios.post('/order/placeOrder', newOrder).then(res => {
        console.log('/order/placeOrder')
        console.log(res)
        Element.Message.success(res.data.data, {duration: 3000})
        this.newOrderDrawer = false
        this.pager.curPage = 0
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },

    saveDraft() {
      this.orderForm.operatorId = this.curUser.id
      this.customerForm.operatorId = this.curUser.id
      let newOrder = {newOrder: this.orderForm, customer: this.customerForm}
      this.$axios.post('/order/draftOrder', newOrder).then(res => {
        console.log('/order/draftOrder')
        console.log(res)
        Element.Message.success(res.data.data, {duration: 3000})
        this.newOrderDrawer = false
        this.pager.curPage = 0
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },

    editOrder() {
      this.orderForm.operatorId = this.curUser.id
      this.$axios.post('/order/modifyOrderBasicInfo', this.orderForm).then(res => {
        console.log('/order/modifyOrderBasicInfo')
        console.log(res)
        Element.Message.success(res.data.data, {duration: 3000})
        // this.editOrderDrawer = false
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },

    editCustomer() {
      this.customerForm.orderId = this.orderForm.id
      this.customerForm.operatorId = this.curUser.id
      console.log(this.licenseFileList)
      let customerDto = {
        customer: this.customerForm,
        licenseFiles: this.licenseFileList,
        ourSourceFiles: this.outsourceFileList,
        printingLicense: this.printingLicenseFileList,
        tradeCertificate: this.tradeLicenseFileList,
        leaseContract: this.contractLicenseFileList
      }

      this.$axios.post('/order/modifyCustomerInfo', customerDto).then(res => {
        console.log('/order/modifyCustomerInfo')
        console.log(res)
        Element.Message.success(res.data.data, {duration: 3000})
        // this.editOrderDrawer = false
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },
    editSchedule() {
      this.scheduleForm.operatorId = this.curUser.id
      this.scheduleForm.orderId = this.orderForm.id
      let scheduleDto = {
        schedule: this.scheduleForm,
        auditTeacherList: this.auditTeachersList,
        applicationFiles: this.applicationFileList,
        trainDateList: this.trainDateList,
        auditDateList: this.auditDateList,
        trainTeacherList: this.trainTeacherList
      }

      this.$axios.post('/order/schedule', scheduleDto).then(res => {
        console.log('/order/schedule')
        console.log(res)
        Element.Message.success(res.data.msg, {duration: 3000})
        this.scheduleForm.id = res.data.data.id
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },

    editAudit() {
      this.auditForm.operatorId = this.curUser.id
      this.auditForm.orderId = this.orderForm.id
      let ncProblemDtoList = []
      let receiptDtoList = []
      for (let i = 0; i < this.ncList.length; i++) {
        let nc = this.ncList[i]
        nc.operatorId = this.curUser.id
        nc.orderId = this.orderForm.id
        console.info(nc)
        let ncFileList = nc.fileList
        let ncProblemDto = {ncProblem: nc, ncFileList: ncFileList}
        console.info('ncProblemDto')
        console.info(ncProblemDto)
        ncProblemDtoList.push(ncProblemDto)
      }
      let receiptTotalMoney = 0.00
      for (let i = 0; i < this.receiptList.length; i++) {
        let receipt = this.receiptList[i]
        receipt.operatorId = this.curUser.id
        receipt.orderId = this.orderForm.id
        if (receipt.receiptMoney == null || receipt.receiptMoney == undefined || receipt.receiptMoney == '') {
          receipt.receiptMoney = 0
          console.info('receiptMoney: ' + receipt.receiptMoney)
        }

        if (receipt.startKilometers == null || receipt.startKilometers == undefined || receipt.startKilometers == '') {
          receipt.startKilometers = 0
        }

        if (receipt.endKilometers == null || receipt.endKilometers == undefined || receipt.endKilometers == '') {
          receipt.endKilometers = 0
        }
        if (receipt.startKilometers != 0 && receipt.endKilometers != 0) {
          let distance = receipt.endKilometers - receipt.startKilometers
          if (distance >= 0) {
            receipt.drivingFee = distance * 1.2
          }
        } else {
          receipt.drivingFee = 0
        }

        if (receipt.parkingFee == null || receipt.parkingFee == undefined || receipt.parkingFee == '') {
          receipt.parkingFee = 0
        }

        if (receipt.bridgeFee == null || receipt.bridgeFee == undefined || receipt.bridgeFee == '') {
          receipt.bridgeFee = 0
        }
        if (receipt.title.search('自驾') != -1) {
          receipt.receiptMoney = parseFloat(receipt.drivingFee) + parseFloat(receipt.parkingFee) + parseFloat(receipt.bridgeFee)
        }
        receiptTotalMoney = parseFloat(receiptTotalMoney) + parseFloat(receipt.receiptMoney)
        console.info(receipt)
        let receiptFileList = receipt.fileList
        let receiptDto = {receipt: receipt, receiptFileList: receiptFileList}
        receiptDtoList.push(receiptDto)
      }
      this.auditForm.receiptTotal = receiptTotalMoney

      let auditFormDto = {
        audit: this.auditForm,
        ncProblemDtoList: ncProblemDtoList,
        trainFileList: this.trainFileList,
        contractFileList: this.contractFileList,
        receiptDtoList: receiptDtoList
      }

      this.$axios.post('/order/train', auditFormDto).then(res => {
        console.log('/order/train')
        console.log(res)
        Element.Message.success(res.data.msg, {duration: 3000})
        this.auditForm.id = res.data.data.id
        // this.editOrderDrawer = false
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },

    editLicense() {
      this.certificateForm.operatorId = this.curUser.id
      this.certificateForm.orderId = this.orderForm.id

      let certificateDto = {license: this.certificateForm, certificateDocs: this.certificateFileList}
      console.info(certificateDto)
      this.$axios.post('/order/license', certificateDto).then(res => {
        console.log('/order/license')
        console.log(res)
        Element.Message.success(res.data.msg, {duration: 3000})
        this.certificateForm.id = res.data.data.id
        // this.editOrderDrawer = false
        this.getOrderList()
      }).catch((error) => {
        console.error(error)
      })
    },

    //上传
    uploadLicenseFile(param) {
      let _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '营业执照/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.licenseFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '营业执照/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.licenseFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },

    handleRemoveLicense(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.licenseFileList = fileList
    },

    //外包资料
    uploadOutSourceFile(param) {
      let _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '外包资料/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.outsourceFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadOutSourceFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadOutSourceFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '外包资料/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.outsourceFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadOutSourceFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadOutSourceFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemoveOutSource(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.outsourceFileList = fileList
    },

    //印刷许可证
    uploadPrintingLicenseFile(param) {
      const _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '印刷许可证/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.printingLicenseFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadPrintingLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadPrintingLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '印刷许可证/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.printingLicenseFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadPrintingLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadPrintingLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemovePrintingLicense(file, fileList) {
      console.log(file);
      this.printingLicenseFileList = fileList
    },

    //贸易登记证
    uploadTradeLicenseFile(param) {
      const _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '贸易登记证/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.tradeLicenseFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadTradeLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadTradeLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '贸易登记证/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.tradeLicenseFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadTradeLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadTradeLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemoveTradeLicense(file, fileList) {
      console.log(file);
      this.tradeLicenseFileList = fileList
    },
    //租赁合同
    uploadContractLicenseFile(param) {
      const _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '租赁合同/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.contractLicenseFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadContractLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadContractLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '租赁合同/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.contractLicenseFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadContractLicenseFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadContractLicenseFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemoveContractLicense(file, fileList) {
      console.log(file);
      this.contractLicenseFileList = fileList
    },

    //申请资料
    uploadApplicationFile(param) {
      const _this = this
      client.put('orderId' + this.orderForm.id + '申请资料/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.applicationFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadApplicationFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadApplicationFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '申请资料/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.applicationFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadApplicationFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadApplicationFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }

      });
    },

    handleRemoveApplication(file, fileList) {
      console.log(file);
      this.applicationFileList = fileList
    },
    addAuditTeacher() {
      this.auditTeachersList.push({name: undefined, phone: undefined, mail: undefined})
    },
    delAuditTeacher(index) {
      this.auditTeachersList.splice(index, 1)
    },

    //咨询合同
    uploadContractFile(param) {
      const _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '咨询合同/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.contractFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadContractFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadContractFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '咨询合同/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.contractFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadContractFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadContractFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemoveContract(file, fileList) {
      console.log(file);
      this.contractFileList = fileList
    },

    //培训资料
    uploadTrainFile(param) {
      const _this = this
      console.log(param)
      client.put('orderId' + this.orderForm.id + '培训资料/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.trainFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadTrainFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadTrainFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '培训资料/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.trainFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadTrainFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadTrainFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemoveTrain(file, fileList) {
      console.log(file);
      this.trainFileList = fileList
    },

    addNC() {
      this.ncList.push({isResolved: false, problemDocs: undefined, resolvedDate: undefined, fileList: []})
    },

    delNc(index) {
      this.ncList.splice(index, 1)
    },

    addReceipt() {
      let titleList = ['打车', '车票/高铁票/机票', '公交/地铁', '顺风车', '自驾1', '自驾2', '自驾3', '自驾4', '自驾5', '住宿费用', '证件/检测费', '审核老师差旅/红包', '其他费用']
      this.receiptList.push({
        title: '',
        detail: undefined,
        startKilometers: undefined,
        endKilometers: undefined,
        receiptMoney: 0.00,
        drivingFee: 0.00,
        parkingFee: 0.00,
        bridgeFee: 0.00,
        receiptFiles: undefined,
        fileList: []
      })
    },

    delReceipt(index) {
      this.receiptList.splice(index, 1)
    },

    //培训资料
    uploadNcFile(param, index) {
      const _this = this
      console.log(param)
      console.log(index)

      _this.$axios.post('/upload/getStsByLogin').then(res => {
        console.info(res.data.data)
        client = new OSS({
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: "oss-" + res.data.data.ossRegion,
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: res.data.data.response.credentials.accessKeyId,
          accessKeySecret: res.data.data.response.credentials.accessKeySecret,
          stsToken: res.data.data.response.credentials.securityToken,
          bucket: res.data.data.ossBucket
        });
        client.put('orderId' + _this.orderForm.id + 'Nc资料/' + param.file.name, param.file).then(function (r1) {
          console.log('put success: %j', r1);
          console.info(_this.ncList)
          console.info(_this.ncList[index])
          console.info(_this.ncList[index].fileList)
          _this.ncList[index].fileList.push({
            name: param.file.name,
            url: r1.url
          });
          return client.get('object');
        }).then(function (r2) {
          // console.log('get success: %j', r2);
        }).catch(function (err) {
          console.error('error: %j', err);
        });
      })



    },
    handleRemoveNc(file, fileList, index) {
      console.log(file);
      console.log(fileList);
      this.ncList[index].fileList = fileList
    },

    //报销凭证
    uploadReceiptFile(param, index) {
      const _this = this
      console.log(param)
      console.log(index)

      _this.$axios.post('/upload/getStsByLogin').then(res => {
        console.info(res.data.data)
        client = new OSS({
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: "oss-" + res.data.data.ossRegion,
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: res.data.data.response.credentials.accessKeyId,
          accessKeySecret: res.data.data.response.credentials.accessKeySecret,
          stsToken: res.data.data.response.credentials.securityToken,
          bucket: res.data.data.ossBucket
        });
        client.put('orderId' + _this.orderForm.id + '报销凭证/' + param.file.name, param.file).then(function (r1) {
          console.log('put success: %j', r1);
          _this.receiptList[index].fileList.push({
            name: param.file.name,
            url: r1.url
          });
          return client.get('object');
        }).then(function (r2) {
          // console.log('get success: %j', r2);
        }).catch(function (err) {
          console.error('error: %j', err);
        });
      })
    },

    handleRemoveReceipt(file, fileList, index) {
      console.log(file);
      console.log(fileList);
      this.receiptList[index].fileList = fileList
    },

    getSts(method, param) {

      const _this = this
      this.$axios.post('/upload/getStsByLogin').then(res => {
        console.info(res.data.data)
        client = new OSS({
          // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
          region: "oss-" + res.data.data.ossRegion,
          // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
          accessKeyId: res.data.data.response.credentials.accessKeyId,
          accessKeySecret: res.data.data.response.credentials.accessKeySecret,
          stsToken: res.data.data.response.credentials.securityToken,
          bucket: res.data.data.ossBucket
        });
        console.log(param)
        method(param)
      })

    },


    //证书
    uploadCertificationFile(param) {
      const _this = this
      client.put('orderId' + this.orderForm.id + '证书/' + param.file.name, param.file).then(function (r1) {
        console.log('put success: %j', r1);
        _this.certificateFileList.push({
          name: param.file.name,
          url: r1.url
        });
        return client.get('object');
      }).then(function (r2) {
        // console.log('get success: %j', r2);
      }).catch(function (err) {
        console.error('error: %j', err);
        let errStr = err.toString()
        if(errStr.search('AccessDeniedError') == -1){
          let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
          let idx = _this.$refs.uploadCertificationFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
          _this.$refs.uploadCertificationFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
          console.log("carryOut  Self")
          _this.$axios.post('/upload/getStsByLogin').then(res => {
            console.info(res.data.data)
            client = new OSS({
              // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
              region: "oss-" + res.data.data.ossRegion,
              // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
              accessKeyId: res.data.data.response.credentials.accessKeyId,
              accessKeySecret: res.data.data.response.credentials.accessKeySecret,
              stsToken: res.data.data.response.credentials.securityToken,
              bucket: res.data.data.ossBucket
            });
            client.put('orderId' + _this.orderForm.id + '证书/' + param.file.name, param.file).then(function (r1) {
              console.log('put success: %j', r1);
              _this.certificateFileList.push({
                name: param.file.name,
                url: r1.url
              });
              return client.get('object');
            }).then(function (r2) {
              // console.log('get success: %j', r2);
            }).catch(function (err) {
              console.error('error: %j', err);
              let errStr = err.toString()
              if(errStr.search('AccessDeniedError') == -1) {
                let uid = param.file.uid // 关键作用代码，去除文件列表失败文件
                let idx = _this.$refs.uploadCertificationFile.uploadFiles.findIndex(item => item.uid === uid) // 关键作用代码，去除文件列表失败文件（uploadFiles为el-upload中的ref值）
                _this.$refs.uploadCertificationFile.uploadFiles.splice(idx, 1) // 关键作用代码，去除文件列表失败文件
              }
            });
          })
        }
      });
    },
    handleRemoveCertification(file, fileList) {
      console.log(file);
      this.certificateFileList = fileList
    },
    handlePreviewCertification(file) {
      console.info(file)
      if (file.url == undefined || file.url == null) {
        this.curFileUrl = ''
      } else {
        this.curFileUrl = file.url
        this.filePreViewDrawer = true
      }
    },

    finishOder() {
      this.$confirm('是否确定结单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        width: '200px'
      }).then(() => {
        this.$axios.post('/order/finishOrder', this.orderForm).then(res => {
          console.log('/order/license')
          console.log(res)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消结单'
        });
      });
    },

    revokeFinishOder() {
      this.$confirm('是否确定撤销结单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        width: '200px'
      }).then(() => {
        this.$axios.post('/order/revokeFinishOder', this.orderForm).then(res => {
          console.log('/order/license')
          console.log(res)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消结单'
        });
      });
    },

    backOder() {
      this.$confirm('是否确定撤单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        width: '200px'
      }).then(() => {
        this.$axios.post('/order/revokeOrder', this.orderForm).then(res => {
          console.log('/order/license')
          console.log(res)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤单'
        });
      });
    },

    revokeBackOder() {
      this.$confirm('是否确定撤销撤单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        width: '200px'
      }).then(() => {
        this.$axios.post('/order/revokeRevokeOder', this.orderForm).then(res => {
          console.log('/order/license')
          console.log(res)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤单'
        });
      });
    },

    filePreViewCLose() {
      console.info('filePreViewCLose')
      this.curFileUrl = ''
    },
    downloadFiles(files) {
      this.setEdit();
      console.info(files)
      if (files != undefined && files != null) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i]
          let fileName = file.name
          let fileUrl = file.url
          console.info(file)
          window.open(fileUrl, '_self'); // 本窗口打开下载，地址栏的地址并不会改变
        }
      }
    },

    downloadFile(fileUrl) {
      this.setEdit();
      console.info(file)
      if (fileUrl != undefined && fileUrl != null) {
        window.open(fileUrl, '_self'); // 本窗口打开下载，地址栏的地址并不会改变
      }
    },
    satis() {
      this.$router.push({
        name: 'statistics'
      })
    },
    setEdit() {
      document.getElementsByTagName("input").disabled = true
    }
  },
  created() {
    //获取当前月份
    const date = new Date();
    const currentMonth = date.getMonth();
    this.checkedTime = this.timeList[currentMonth]
    this.getUserInfo();
    this.getOrganList();
    this.getProjectList();
    this.getTrainTeacherList();
    this.getAuditTeacherList();
    this.getOrderList();
  }
}
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  height: 100%;
}

.user_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}

.user_detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.logo-img {
  width: 120px;
  margin-left: 15px;
}

.user_photo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.order_status {
  margin-left: 30px;
}

.order_filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 10px;
}

.order_filter1 {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.order_stauts_filter {
  margin-top: 10px;
  margin-left: 25px;
  display: flex;
  flex-direction: row;
}

.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.audit_teacher {
  display: flex;
  flex-direction: column;
}

.orderForm {
  margin-left: 20px;
}

.customerForm {
  margin-left: 20px;
}

.scheduleForm {
  margin-left: 20px;
}

.title {
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.sub_title {
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: bold;
}

.el-row >>> .el-col {
  margin: 0px;
  padding: 0px;
  height: 40px;
}

.edit_msg {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100px;
  height: 30px;
  font-size: 16px;
}

.edit_bottom {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 180px;
  height: 50px;
  font-size: 25px;
}

.audit_parent {
  display: flex;
  align-items: center;
}

::v-deep .anchorBL {
  display: none !important;
}

.nc-item {
  display: flex;
  align-items: baseline;
}

.full-payment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.receipt_child {
  display: flex;
  flex-direction: column;
}

.common_receipt {
  display: flex;
  flex-direction: row;
}

.extra_receipt {
  display: flex;
  flex-direction: row;
}

.download_btn {
  margin-top: 15px;
}

.order_item {

}

.copy_right {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.black {
}

.blue {
  color: blue;
}
</style>
